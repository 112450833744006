
export default {
    colors: [
        '#d1e2ff',
        '#c4ecff',
        '#c2f5f0',
        '#cef5d1',
        '#feeab6',
        '#ffe0cd',
        '#ffd4e0',
        '#fbd1fc',
        '#e0dafd',
        '#e5e9f0'

    ]
}